import { Injectable } from "@angular/core";
import {
  ApiId,
  IAppRecord,
  IOfflineAllDocs,
  IOfflineRecord,
  OFFLINE_QUEUE_MODEL,
} from "@app/models";
import PouchDB from "pouchdb";
import { from, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import * as uuid from "uuid";

@Injectable({
  providedIn: "root",
})
export class PouchService {
  public pdb: any;

  constructor() {
    this.databaseCreate();
  }

  public slugId(model: string, id: ApiId) {
    return `${model}:${id}`;
  }

  public unslugId(id: ApiId) {
    const parts = id.toString().split(":");
    return parts[parts.length - 1];
  }

  // TODO put this in to proper place
  public generatePouchId() {
    return uuid.v4();
  }

  private preparePouchId(values: any): ApiId {
    return values._id || values.id || this.generatePouchId();
  }

  // TODO move dependend methonds to pouch service and make normalize private
  public normalizeValues(values2: any): any {
    let values = { ...values2 };

    return values;
  }

  public databaseCreate() {
    this.pdb = new PouchDB("dive_ops.db", {
      revs_limit: 1,
      auto_compaction: true,
    });
  }

  public databaseReset() {
    this.pdb.destroy().then(() => {
      this.databaseCreate();
    });
  }

  public docId(model: string, id: ApiId) {
    return `${model}:${id}`;
  }

  public _create(values: IAppRecord): Observable<IOfflineRecord> {
    if (!values._id) {
      return throwError(() => new Error("ID is required to create a record."));
    }
    return from(
      this.pdb.put(values).then(({ id }) => this.pdb.get(id))
    ) as Observable<IOfflineRecord>;
  }

  public create(model: string, values: any): Observable<IAppRecord> {
    return this._create({
      ...values,
      _id: this.slugId(model, values._id),
    }).pipe(
      map((doc) => {
        delete doc._rev;
        return { ...doc, _id: this.unslugId(doc._id) } as IAppRecord;
      })
    );
  }

  public _update(values: IAppRecord): Observable<IOfflineRecord> {
    if (!values._id) {
      return throwError(() => new Error("ID is required to update a record."));
    }
    return from(
      this.pdb
        .get(values._id)
        .then((doc: IOfflineRecord) =>
          this.pdb.put({
            ...doc,
            ...values,
          })
        )
        .catch(() => this.pdb.put(values))
        .then(() => this.pdb.get(values._id))
    ) as Observable<IOfflineRecord>;
  }

  public update(model: string, id: ApiId, values: any): Observable<IAppRecord> {
    return this._update({
      ...values,
      _id: this.slugId(model, id),
    }).pipe(
      map((updatedValues: IOfflineRecord) => {
        delete updatedValues._rev;
        return {
          ...updatedValues,
          _id: this.unslugId(updatedValues._id),
        } as IAppRecord;
      })
    );
  }

  public upsert(model: string, values: any): Observable<IAppRecord> {
    return this._update({
      ...values,
      _id: this.slugId(model, this.preparePouchId(values)),
    }).pipe(
      map((updatedValues: IOfflineRecord) => {
        delete updatedValues._rev;
        return {
          ...updatedValues,
          _id: this.unslugId(updatedValues._id),
        } as IAppRecord;
      })
    );
  }

  public _read(_id: string): Observable<IOfflineRecord> {
    return from(
      this.pdb.get(_id, {
        revs: true,
        revs_info: true,
        conflicts: true,
      })
    ) as Observable<IOfflineRecord>;
  }

  public read(model: string, id: ApiId): Observable<IAppRecord> {
    const pdbId = this.slugId(model, id);
    return this._read(pdbId).pipe(
      map((doc: IOfflineRecord) => {
        delete doc._rev;
        return { ...doc, _id: id } as IAppRecord;
      })
    );
  }

  public _delete(_id: string): Observable<boolean> {
    return from(
      this.pdb.get(_id).then((doc: any) => {
        return this.pdb.remove(doc);
      })
    ).pipe(map(() => true));
  }

  public delete(model: string, id: ApiId): Observable<boolean> {
    return this._delete(this.slugId(model, id));
  }

  public _search(model: string): Observable<IOfflineRecord[]> {
    return from(
      this.pdb
        .allDocs({
          include_docs: true,
          startkey: `${model}:`,
          endkey: `${model}:\ufff0`,
        })
        .then((docs: IOfflineAllDocs) => docs.rows.map((row) => row.doc))
    ) as Observable<IOfflineRecord[]>;
  }

  public search(model: string): Observable<IAppRecord[]> {
    return this._search(model).pipe(
      map((data) =>
        data.map((row) => {
          delete row._rev;
          return { ...row, _id: this.unslugId(row._id) };
        })
      )
    );
  }

  public searchId(_id: string): Observable<number> {
    return from(
      this.pdb.allDocs({ include_docs: true }).then((docs: IOfflineAllDocs) => {
        const rows = docs.rows
          .map((row) => row.doc)
          .filter((doc) => !doc._id.includes(OFFLINE_QUEUE_MODEL))
          .filter((doc) => doc._id.includes(_id));
        if (rows.length && rows[0]["id"]) {
          return rows[0]["id"] as number;
        } else {
          throw new Error(`"Offline ID ${_id} not found"`);
        }
      })
    ) as Observable<number>;
  }

  public bulkDocs(docs: any[]): Observable<any[]> {
    return from(this.pdb.bulkDocs(docs)) as Observable<any[]>;
  }
}
